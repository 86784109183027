<template>
  <div v-if="visible">
    <div class="overlay" @click="clickOverlay"></div>
    <div class="modal">
      <div class="header">
        <div class="flex-fill">
          {{headerText}}
        </div>
        <div class="close-btn-container">
          <button v-if="modalType === 'default'" @click.prevent="closeModal" class="close-btn">
            <CloseRound/>
          </button>
        </div>
      </div>
      <div v-if="modalType !== 'loading'" class="content">
        {{contentText}}
      </div>
      <div class="loading-container" v-if="modalType === 'loading'">
        <app-loader color="#ccc" :size="75"></app-loader>
      </div>
      <div v-if="modalType === 'confirm'" class="footer">
        <app-button @click="confirmModal" is-small>Confirm</app-button>
        <app-button @click="cancelModal" is-small style-type="secondary" class="cancel-btn">Cancel</app-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {CloseRound} from '@vicons/material'
import {ref} from 'vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppLoader from '@/components/ui/AppLoader.vue';

const visible = ref(false);
const modalType = ref<'default' | 'confirm' | 'loading'>('default');
const confirmCallback = ref<(userConfirmed: boolean) => void>();
const headerText = ref('')
const contentText = ref('')

defineExpose({
  visible,
  headerText,
  contentText,
  modalType,
  confirmCallback
});

function clickOverlay(): void {
  if (modalType.value === 'default') {
    closeModal();
  }
}

function closeModal() {
  visible.value = false;
}
function cancelModal() {
  closeModal();
  confirmCallback.value?.(false);
}

function confirmModal() {
  closeModal();
  confirmCallback.value?.(true);
}

</script>

<style lang="scss" scoped>
@import 'src/styles/z-index';
@import 'src/styles/colors';
@import 'src/styles/shadow';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-modal-background;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: $z-index-modal;
  background: white;
  border-radius: 6px;
  min-width: 400px;

  box-shadow: $shadow;

  .header, .content {
    display: block;
  }

  .header {
    padding: 14px 18px;
    display: flex;
    font-weight: bold;
    font-size: 28px;
    border-bottom: 1px solid $border-color;
  }

  .content {
    padding: 18px 18px;
  }

  .footer {
    padding: 0 18px 18px;

    .cancel-btn {
      margin-left: 8px;
    }
  }

  .loading-container {
    text-align: center;
    padding: 16px 12px 12px;
  }

  .close-btn-container {
    position: relative;
    width: 26px;
    align-self: stretch;
    .close-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-60%);
      cursor: pointer;
      color: black;
      width: 26px;
      height: 26px;
    }
  }

}
</style>