
<template>
  <app-header v-if="routerInitialized && !hideHeader"></app-header>
  <div class="content">
    <app-background/>
    <router-view/>
  </div>
  <div v-if="!hideFooter" class="privacy-policy-container">
    <a href="/privacy-policy/Privacy%20Policy%20ENG.pdf" target="_blank">Privacy policy</a>
    <div v-if="showCommitHash" class="commit-hash">
      Commit Hash: {{ commitHash }}
    </div>
  </div>
  <app-global-modal ref="globalModal"></app-global-modal>
  <app-global-alert ref="globalAlert"></app-global-alert>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppBackground from '@/components/layout/background/AppBackground.vue';
import AppGlobalModal from '@/components/layout/AppGlobalModal.vue';
import {registerModal} from '@/hooks/useModal';
import {useRoute} from 'vue-router';
import {routeDefinitions} from '@/router/routeDefinitions';
import AppGlobalAlert from '@/components/layout/AppGlobalAlert.vue';
import {registerAlert} from '@/hooks/useAlert';
import {getVersioning} from "@/api/versioning.api";

const globalModal = ref<typeof AppGlobalModal>()
const globalAlert = ref<typeof AppGlobalAlert>()
const route = useRoute();

// route.name is undefined for one tick on application load.
// We can check for this to reduce flashing of the header while loading the application
const routerInitialized = computed(() => route.name !== undefined);

const hideHeader = computed(() => route.name === routeDefinitions.LOGIN.name);
const hideFooter = computed(() => route.name === routeDefinitions.ISSUE_DETAILS.name);

const showCommitHash = ref<boolean>(true);
const commitHash = ref<string>();

onMounted(async () => {
  registerModal(globalModal.value);
  registerAlert(globalAlert.value);
  await loadVersioning();
})

async function loadVersioning(): Promise<void> {
  const versioningResponse = await getVersioning();
  commitHash.value = versioningResponse.latestCommitHash;
  if (versioningResponse.environment === 'prod') {
    showCommitHash.value = false;
  }
}

</script>

<style lang="scss" scoped>
@use 'src/styles/paddings';
@use 'src/styles/colors';

.commit-hash {
  margin-left: 10px;
  color: #939393
}
.content {
  @extend .horizontal-page-padding;
  @extend .vertical-page-padding;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.privacy-policy-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bolder;

  a {
    color: colors.$kunlabora-blue
  }

}
</style>
