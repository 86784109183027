import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {routeDefinitions} from '@/router/routeDefinitions';

const routes: Array<RouteRecordRaw> = Object.values(routeDefinitions);
const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
