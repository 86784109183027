import AppGlobalModal from '@/components/layout/AppGlobalModal.vue';

let modalElement: typeof AppGlobalModal | undefined;

export function registerModal(modal: typeof AppGlobalModal | undefined) {
    modalElement = modal;
}

const useModal = () => {
    const openModal = (content: string, header = 'Something went wrong') => {
        if(modalElement) {
            modalElement.visible = true;
            modalElement.headerText = header;
            modalElement.contentText = content;
            modalElement.modalType = 'default';
        }
    }

    const openConfirmModal: (header: string, content: string) => Promise<boolean> = (header: string, content: string) => {
        return new Promise(resolve => {
            if(modalElement) {
                modalElement.visible = true;
                modalElement.headerText = header;
                modalElement.contentText = content;
                modalElement.modalType = 'confirm';
                modalElement.confirmCallback = resolve;
            }
        })
    }

    const openLoadingModal = (header: string) => {
        if(modalElement) {
            modalElement.visible = true;
            modalElement.headerText = header;
            modalElement.modalType = 'loading';
        }
    }

    const closeModal = () => {
        if(modalElement) {
            modalElement.visible = false;
        }
    }

    return {openModal, openConfirmModal, openLoadingModal, closeModal};
};

export default useModal;