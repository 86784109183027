<template>

  <header class="header">
    <router-link class="logo" to="/">
      <img alt="Kunlabora logo" src="../../assets/logo.svg">
    </router-link>

    <div class="header-right-container">
      <nav class="header-links">
        <router-link v-if="isAdmin" to="/admin/users" class="header-link cy-header-link-users">
          Users
        </router-link>
        <router-link to="/issues" class="header-link cy-header-link-issues">
          Issues
        </router-link>
        <router-link to="/settings" class="header-link cy-header-link-settings">
          Settings
        </router-link>
        <button v-if="showLogout" type="button" class="logout-button cy-header-logout-button" @click="logout">
          <img src="../../assets/logout.svg" alt="Logout icon" class="logout-icon"/> Logout
        </button>
      </nav>
    </div>

  </header>

</template>

<script setup lang="ts">
import {getGroups, signOutFunction} from '@/router/authentication';
import {computed, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';

const route = useRoute();
const showLogout = computed(() => {
  return route.name !== 'login';
});

function logout() {
  signOutFunction();
}

const isAdmin = ref<boolean>();

onMounted(async () => {
  isAdmin.value = isAdminInUserGroups(await getGroups());
})

function isAdminInUserGroups(userGroups: string[] | undefined): boolean {
  return !!userGroups?.find(userGroup => userGroup === 'ADMIN');
}

</script>

<style scoped lang="scss">
@import 'src/styles/paddings';
@import 'src/styles/z-index';
@import 'src/styles/responsive';
@import 'src/styles/fonts';
@import 'src/styles/colors';

$header-mobile-height: 80px;
$header-desktop-height: 112px;

.push-content {
  width: 100%;
  height: $header-mobile-height;
}

@include desktop {
  .push-content {
    height: $header-desktop-height;
  }
}

.header {
  @extend .horizontal-page-padding;

  display: flex;
  position: sticky;
  height: $header-mobile-height;
  top: 0;

  box-shadow: 0 0 0.75rem #00000024;
  background-color: white;

  z-index: $z-index-header;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .logo, img {
    height: 41px;
    align-self: center;
    justify-self: center;
    margin: auto;
  }

  .logout-button {
    @extend .large-text;
    display: flex;
    align-items: center;
    color: #363636;
  }

  .logout-icon {
    width: 28px;
    height: 28px;
    margin-right: 5px;
  }

  .header-right-container {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .header-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-left: fill;
    gap: 1rem;
    max-width: 50rem;
    width: 100%;
  }

  .header-link {
    @extend .large-text;

    color: inherit;
    text-decoration: none;

    &:hover, &.router-link-active {
      color: $kunlabora-blue;
      text-decoration: underline;
    }
  }
}

@include desktop {
  .header {
    height: $header-desktop-height;

    .header-links {
      max-width: 35rem;
      justify-content: space-between;
    }
  }
}

</style>
