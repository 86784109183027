import {NavigationGuardWithThis} from 'vue-router';
import {getGroups, getUser, Groups} from '@/router/authentication';

export const LOGIN_ROUTE_NAME = 'login';
export const NOT_AUTHORIZED_ROUTE_NAME = 'not-authorized';

export const requiresAuth: NavigationGuardWithThis<void> = async (to, from, next) => {
    const user = await getUser()
    if (!user) {
        return next({
            name: LOGIN_ROUTE_NAME
        });
    }
    return next();
}

export const requiresGroup: (...groups: Groups[]) => NavigationGuardWithThis<void> = (...groups: Groups[]) => async (to, from, next) => {
    const userGroups = await getGroups()
    if(userGroups && userGroups.find(userGroup => groups.includes(userGroup as Groups))) {
        return next();
    } else {
        return next({
            name: NOT_AUTHORIZED_ROUTE_NAME
        });
    }
};