<template>
  <div v-if="!hidden" class="lds-ring loader"
       :class="{'show-after-delay': showAfterDelay}"
       :style="{...ldsRingStyle}">
    <div :style="ldsRingDivStyle"></div>
    <div :style="ldsRingDivStyle"></div>
    <div :style="ldsRingDivStyle"></div>
    <div :style="ldsRingDivStyle"></div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue';

const props = defineProps({
  size: {
    type: Number,
    default: 100,
  },
  color: {
    type: String,
    default: '#fff',
  },
  showAfterDelay: {
    type: Number,
    default: 0
  }
});

const hidden = ref(true);

onMounted(() => {
  if (props.showAfterDelay) {
    setTimeout(() => {
      hidden.value = false;
    }, props.showAfterDelay);
  } else {
    hidden.value = false;
  }
})

const ldsRingStyle = computed(() => {
  const height = 80 * (props.size / 100)
  return {
    width: height + 'px',
    height: height + 'px',
  }
})

const ldsRingDivStyle = computed(() => {
  const height = 64 * (props.size / 100)
  const borderWidth = 8 * (props.size / 100);
  const margin = 8 * (props.size / 100);
  return {
    width: height + 'px',
    height: height + 'px',
    'border-width': borderWidth + 'px',
    margin: margin + 'px',
    'border-color': props.color + ' transparent transparent transparent'
  }
})
</script>

<style lang="scss">
@keyframes loader-show-after-delay {
  0% {
    opacity: 0;
    height: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<style lang="scss" scoped>
.loader {
  &.show-after-delay {
    animation: loader-show-after-delay 400ms;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>