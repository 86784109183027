import {fetchAuthSession, Hub} from '@aws-amplify/core';
import router from '@/router/index';
import {LOGIN_ROUTE_NAME} from '@/router/auth-guards';
import {AuthUser, getCurrentUser, signOut} from '@aws-amplify/auth';

export type Groups = 'ADMIN';

Hub.listen("auth", async (data) => {
    if (data.payload.event === 'signedOut') {
        await router.push({name: LOGIN_ROUTE_NAME});
    } else if (data.payload.event === 'signedIn') {
        await router.push({path: '/'});
    }
});

export async function getUser(): Promise<AuthUser | null> {
    try {
        const data = await getCurrentUser();
        if (data) {
            return data;
        } else {
            return null;
        }
    } catch (e) {
        // Auth.currentAuthenticatedUser() throws an error when the user is not logged in
        return null;
    }
}

export async function getGroups(): Promise<string[] | undefined> {
    let accessToken = (await fetchAuthSession())
        ?.tokens
        ?.accessToken;
    return accessToken
        ?.payload['cognito:groups'] as string[];
}

export async function getAccessToken() {
    let authSession = await fetchAuthSession();
    return authSession?.tokens?.accessToken.toString();
}

export async function signOutFunction() {
    if (await getUser()) {
        return signOut();
    }
}
