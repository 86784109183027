import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import '@aws-amplify/ui-vue/styles.css';
import './styles.scss'
import {getConfig} from '@/api/config.api';
import {routeDefinitions} from '@/router/routeDefinitions';
import {I18n, ResourcesConfig} from '@aws-amplify/core';
import {Amplify} from 'aws-amplify';

async function configureAmplify() {
    return getConfig().then(config => {
        Amplify.configure({
            Auth: {
                userPoolWebClientId: config.userPoolWebClientId,
                Cognito: {
                    userPoolId: config.userPoolId,
                    userPoolClientId: config.userPoolWebClientId,
                }
            }
        } as ResourcesConfig);
        I18n.putVocabularies({
            en: {
                'Temporary password has expired and must be reset by an administrator.':
                    'Temporary password has expired. Click on reset password to create a new password.'
            }
        })
    }).catch((e) => {
        // We want to log the error to the console here
        // eslint-disable-next-line
        console.error(e);
        return router.push({name: routeDefinitions.ERROR_PAGE.name});
    });
}

configureAmplify().finally(() => {
    const app = createApp(App);
    app.use(router)
        .mount('#app');
});
