import {RouteRecordRaw} from 'vue-router';
import {getGroups, Groups, signOutFunction} from '@/router/authentication';
import {LOGIN_ROUTE_NAME, NOT_AUTHORIZED_ROUTE_NAME, requiresAuth, requiresGroup} from '@/router/auth-guards';
import {asType} from '@/util/type-check';

const ISSUES_ROUTE_NAME = 'issues';
const ADMIN_USERS_ROUTE_NAME = 'admin/users';

export const routeDefinitions = {
    INDEX: <RouteRecordRaw>{
        path: '/',
        name: 'home',
        beforeEnter: [requiresAuth, async (to, from, next) => {
            const groups = await getGroups();
            if (groups && groups.includes(asType<Groups>('ADMIN'))) {
                return next({name: ADMIN_USERS_ROUTE_NAME});
            } else {
                return next({name: ISSUES_ROUTE_NAME});
            }
        }]
    },
    ADMIN_VIEW_USERS: <RouteRecordRaw>{
        path: '/admin/users',
        name: ADMIN_USERS_ROUTE_NAME,
        component: () => import('../views/admin/AdminUsersView.vue'),
        beforeEnter: [requiresAuth, requiresGroup('ADMIN')]
    },
    ADMIN_CREATE_USER: <RouteRecordRaw>{
        path: '/admin/create-user',
        name: 'admin/create-user',
        component: () => import('../views/admin/AdminCreateUserView.vue'),
        beforeEnter: [requiresAuth, requiresGroup('ADMIN')]
    },
    ADMIN_EDIT_USER: <RouteRecordRaw>{
        path: '/admin/user/:email',
        name: 'admin/edit-user',
        component: () => import('../views/admin/AdminEditUserView.vue'),
        beforeEnter: [requiresAuth, requiresGroup('ADMIN')],
    },
    VIEW_ISSUES: <RouteRecordRaw>{
        path: '/issues',
        name: ISSUES_ROUTE_NAME,
        component: () => import('../views/user/IssuesView.vue'),
        beforeEnter: requiresAuth
    },
    CREATE_ISSUE: <RouteRecordRaw>{
        path: '/create-issue',
        name: 'create-issue',
        component: () => import('../views/user/CreateIssueView.vue'),
        beforeEnter: requiresAuth
    },
    ISSUE_DETAILS: <RouteRecordRaw>{
        path: '/issue/:id',
        name: 'issue-details',
        component: () => import('../views/user/issue-details/IssueDetailsView.vue'),
        beforeEnter: requiresAuth
    },
    EDIT_SETTINGS: <RouteRecordRaw>{
        path: '/settings',
        name: 'edit-settings',
        component: () => import('../views/user/EditSettingsView.vue'),
        beforeEnter: requiresAuth
    },
    LOGIN: <RouteRecordRaw>{
        path: '/login',
        name: LOGIN_ROUTE_NAME,
        component: () => import('../views/LoginView.vue'),
        beforeEnter: async () => {
            // Amplify will hide its login prompt if the user is logged in
            // So we should sign out before the user navigates to the login page otherwise that page is empty
            await signOutFunction();
        }
    },
    ERROR_PAGE: <RouteRecordRaw>{
        path: '/error',
        name: 'error',
        component: () => import('../views/ErrorView.vue')
    },
    NOT_AUTHORIZED_PAGE: <RouteRecordRaw>{
        path: '/not-authorized',
        name: NOT_AUTHORIZED_ROUTE_NAME,
        component: () => import('../views/NotAuthorizedView.vue')
    },
};
