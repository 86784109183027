<template>
  <div v-if="isVisible">
    <div class="alert" :class="[alertType, {'fade-in': isVisible}, {'fade-out': isClosing}]" @click="close">
      <span class="close-icon">x</span>
      <h4 class="cy-alert-title"><strong>{{ title }}</strong></h4>
      {{ contentText }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';

const isVisible = ref(false);
const isClosing = ref(false);
const alertType = ref<'success' | 'error' | 'info'>('success');
const contentText = ref('')

const fixedTimeoutInMs = 4250;
let activeTimeout: unknown;

function trigger() {
  if (isVisible.value) {
    isClosing.value = true;
    new Promise(resolve => setTimeout(resolve, 250)).then(() => {
      close();
      showAlert();
    });
  } else {
    showAlert();
  }
}

function showAlert() {
  isVisible.value = true;
  isClosing.value = false;
  activeTimeout = setTimeout(() => isVisible.value = false, fixedTimeoutInMs);
}

function close() {
  isVisible.value = false;
  isClosing.value = false;
  clearTimeout(activeTimeout as number);
}

const title = computed(() => {
  if (alertType.value === 'success') {
    return 'Success';
  }
  if (alertType.value === 'error') {
    return 'Something went wrong'
  }
  return 'Info'
});

defineExpose({
  contentText,
  alertType,
  trigger
});

</script>

<style lang="scss" scoped>
@import 'src/styles/z-index';
@import 'src/styles/colors';
@import 'src/styles/shadow';

.alert {
  color: white;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  backdrop-filter: blur(2px);
  min-width: 250px;
  padding: 1em;
  margin-left: -125px;
  position: fixed;
  z-index: $z-index-alert;
  left: 50%;
  top: 75px;
  cursor: pointer;
  box-shadow: $shadow;
}

.alert.success {
  background: #f6fcf2;
  color: $kunlabora-green;
  border-color: $kunlabora-green;
}

.alert.error {
  background: #f8e7e7;
  color: #ff464d;
  border-color: #ff464d;
}

.alert.info {
  background: #e5effc;
  color: $kunlabora-blue;
  border-color: $kunlabora-blue;
}

h4 {
  margin: 0;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0.5em;
}

.alert.fade-in {
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.alert.fade-out {
  -webkit-animation: fadeout 0.25s;
  animation: fadeout 0.25s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 75px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 75px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 75px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 75px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
</style>