import AppGlobalAlert from '@/components/layout/AppGlobalAlert.vue';

let alertElement: typeof AppGlobalAlert | undefined;

export function registerAlert(alert: typeof AppGlobalAlert | undefined) {
    alertElement = alert;
}

const useAlert = () => {

    const triggerSuccessAlert = (content: string) => {
        triggerAlert(content, 'success');
    };

    const triggerErrorAlert = (content: string) => {
        triggerAlert(content, 'error');
    };

    const triggerInfoAlert = (content: string) => {
        triggerAlert(content, 'info');
    };

    function triggerAlert(content: string, alertType: string) {
        if (alertElement) {
            alertElement.contentText = content;
            alertElement.alertType = alertType;
            alertElement.trigger();
        }
    }

    return {triggerSuccessAlert, triggerErrorAlert, triggerInfoAlert};
};

export default useAlert;