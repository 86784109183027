import axios, {Axios, AxiosError, AxiosResponse} from 'axios';
import {getAccessToken} from '@/router/authentication';

export function getAxios(errorHandler: (response: AxiosResponse) => void = () => undefined, addAccessToken = true): Axios {
    const instance = axios.create();

    if (addAccessToken) {
        // Add X-Authorization header with access token to all requests
        instance.interceptors.request.use(async (request) => {
            if(!request.url?.includes('password')) {
                request.headers['X-Authorization'] = await getAccessToken();
            }
            return request;
        })
    }

    // The response interceptor is not called when a request fails.
    // Setting the validateStatus validator to always return true allows the request to pass to the interceptor
    // In the interceptor, we will build and throw an AxiosError manually.
    instance.defaults.validateStatus = () => true;
    instance.interceptors.response.use((response) => {
        if (response.status >= 400) {
            errorHandler(response);
            throw createAxiosError(response);
        }
        return response;
    });

    return instance;
}

/**
 * ~ see enhanceError.js
 */
function createAxiosError(response: AxiosResponse): AxiosError {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: any = new Error(`Request failed. ${response.status}: ${response.statusText}`)
    error.request = response.request;
    error.response = response;
    error.isAxiosError = true;

    error.toJSON = function toJSON() {
        return {
            message: this.message,
            stack: this.stack,
            config: this.config,
            code: this.code,
            status: this.response && this.response.status ? this.response.status : null
        };
    }

    return error as AxiosError;
}